import React, { useCallback, useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import { formatMoney } from "../../utils/formatMoney";
import { ApiClient } from "../../services/api/ApiClient";
import { ITreatment } from "../../types/models/Treatments";
import { Forms } from "../../forms/Forms";

export const TreatmentsTab: React.FC = () => {
  const [treatments, setTreatments] = useState<ITreatment[]>([])
  const [treatment_id, setTreatmentId] = useState<number | null>(null)
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false)

  const refresh = useCallback(() => {
    ApiClient.treatments.index().then((response) => {
      setTreatments(response)
    })
  }, [])
  useEffect(() => {
    refresh()
  }, [])

  const handleEdit = (treatment_id: number) => {
    setTreatmentId(treatment_id)
    setIsFormVisible(true)
  }


  const handleDelete = (id: number) => {
    if (window.confirm('Seguro que desea eliminar el tratamiento?')) {
      ApiClient.treatments.delete(id).then(() => {
        refresh()
      })
    }
  }

  const handleFormClose = () => {
    setIsFormVisible(false)
    refresh()
  }
  return (
    <>
      <div className='d-flex flex-row justify-content-between align-items-center'>
        <h1>Facturables</h1>
        <Button className='rounded-0' size='sm' variant='outline-primary' onClick={() => setIsFormVisible(true)}>Nuevo</Button>
      </div>

      {isFormVisible && <Forms.Treatment treatment_id={treatment_id} onClose={() => handleFormClose()} />}
      <Table   hover size='sm'>
        <thead>
          <tr>
            <th>Codigo</th>
            <th>Nombre</th>
            <th>Precio</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {treatments.map((treatment) => (
            <tr key={treatment.id}>
              <td>{treatment.id}</td>
              <td>{treatment.name}</td>
              <td>{formatMoney(treatment.base_price)}</td>
              <td>
                <Button className='rounded-0 mx-1 btn-xs' variant='outline-warning' onClick={() => handleEdit(treatment.id)}>Editar</Button>
                <Button className='rounded-0 btn-xs'  variant='outline-danger' onClick={() => handleDelete(treatment.id)}>Eliminar</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>

  )
}